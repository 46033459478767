import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { ButtonsPrimary } from '../components/atoms/buttons';
import ContextualCat from '../components/ContextualCta';
import Footer from '../components/Footer';
import Deindex from '../components/Deindex';
import Header from '../components/Header';
import PageTitle from '../components/PageTitle';
import PropsPage404 from '../interface/template';
import './style.scss';

const Page404: React.SFC<any> = (props: PropsPage404) => {
  const { pageContext } = props;
  const { pageName } = pageContext;
  const { t } = useTranslation();

  return (
    <>
      <Deindex />
      <Header showBackground showLanguages={false} pageName={pageName}>
        <PageTitle
          title={t('not-found:notFound.mainHeadline.title')}
          subtitle={t('not-found:notFound.mainHeadline.subtitle')}
        >
          <ButtonsPrimary className="visible">Go back home</ButtonsPrimary>
        </PageTitle>
      </Header>
      <ContextualCat />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "en" }
        ns: {
          regex: "/(not-found)|(menu)|(eyebrow)|(breadcrumbs)|(contextual-cta)|(seo)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default Page404;
